        <template>
  <div
      >
    <b-row
          >
                                    <b-col
                                                                                                >
                            <users-view
  api="http://api.bc-sport.ru/api/partners"
/>            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import UsersView from '@core/components/user/users-view/UsersView.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                          UsersView,
                                          },
  setup() {
    
      },
}
</script>
    