<template>
  <b-card>

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.profile.avatar"
            :text="avatarText(userData.profile.fio)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.profile.fio }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: $route.name.replace('-view', '-edit'), params: { id: userData.id } }"
                variant="primary"
              >
                Редактировать
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Удалить
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div
          v-if="roleSlug === 'player'"
          class="d-flex align-items-center mt-2"
        >
          <div
            v-if="userData.subscription"
            class="d-flex align-items-center"
          >
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DribbbleIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.subscription.count }}
              </h5>
              <small>Осталось тренировок</small>
            </div>
          </div>

          <div
            v-if="userData.visited && userData.visited.visited_at"
            class="d-flex align-items-center ml-1"
          >
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.visited.visited_at | moment('DD.MM.YYYY') }}
              </h5>
              <small>Дата последней тренировки</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Роль</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.roles && userData.roles.map(item => item.name).join(', ') }}
            </td>
          </tr>
          <tr
            v-if="roleSlug !== 'superadmin' && roleSlug !== 'partner'"
          >
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Дата рождения</span>
            </th>
            <td class="pb-50">
              {{ userData.profile.dob }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Город</span>
            </th>
            <td class="pb-50">
              {{ userData.profile.city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Телефон</span>
            </th>
            <td>
              {{ userData.profile.phone }}
            </td>
          </tr>
          <tr v-if="roleSlug === 'player'">
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Родитель</span>
            </th>
            <td>
              {{ userData.profile.parent_fio }}
            </td>
          </tr>
          <tr
            v-if="roleSlug === 'player'"
          >
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Телефон родителя</span>
            </th>
            <td>
              {{ userData.profile.parent_phone }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar,
  BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const roleSlug = props.userData.roles[0].slug

    return {
      avatarText,
      roleSlug,
    }
  },
}
</script>

<style>

</style>
