<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          sm="12"
        >
          <user-view-user-info-card
            :user-data="userData"
          />
        </b-col>
        <b-col
          v-if="userData.roles[0].slug === 'player'"
          sm="12"
        >
          <b-tabs
            pills
          >
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">Посещения тренировок</span>
              </template>

              <b-card>
                <basic-table
                  :columns="visitsColumns"
                  api="http://api.bc-sport.ru/api/visits"
                  :params="{
                    user_id: $route.params.id,
                  }"
                />
              </b-card>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">История оплат</span>
              </template>

              <b-card>
                <basic-table
                  :columns="financeColumns"
                  api="http://api.bc-sport.ru/api/finances"
                  :params="{
                    user_id: $route.params.id,
                  }"
                />
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import userStoreModule from '../userStoreModule'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BasicTable,

    // Local Components
    UserViewUserInfoCard,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', {
      api: props.api,
      id: router.currentRoute.params.id,
    })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const financeColumns = [
      { key: 'subscription.subscription_type', sortable: false, label: 'Абонемент' },
      { key: 'price', sortable: false, label: 'Цена' },
      { key: 'receipt_type.name', sortable: false, label: 'Тип поступления' },
      { key: 'created_at', sortable: false, label: 'Дата платежа' },
    ]

    const visitsColumns = [
      { key: 'event.hall.name', sortable: false, label: 'Зал' },
      { key: 'event.admin.profile.fio', sortable: false, label: 'Администратор' },
      { key: 'event.trainer.profile.fio', sortable: false, label: 'Тренер' },
      { key: 'visited_at', sortable: false, label: 'Дата посещения' },
    ]

    return {
      userData,
      financeColumns,
      visitsColumns,
    }
  },
}
</script>

<style>

</style>
